import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
//import { Component, OnInit, Input } from '@angular/core';
//import { LicensingService } from 'src/app/core/services/shared/licensing.service';
import { stream } from 'xlsx/types';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { LicensingService } from 'src/app/core/services/schools/licensing.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss']
})

/**
 * Horizontal-layout component
 */
export class HorizontalComponent implements OnInit, AfterViewInit {
  // obj: LicenseActivator = new LicenseActivator();
   @Input() currentUser: any;
  // year: number;
  //constructor(protected licensingService: LicensingService) { }
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
    // this.obj.key = '';
    // this.year = (new Date()).getFullYear();
    document.body.setAttribute('data-layout', 'horizontal');
    document.body.setAttribute('data-topbar', 'light');
    document.body.removeAttribute('data-sidebar');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.removeAttribute('data-sidebar-small');
    // this.authenticationService.currentUser.subscribe(
    //   (result) => {
    //     this.currentUser = result;
    //     console.log(result);
    //   //  this.getAvatar(this.currentUser?.avatar);
    //     // if (this.currentUser?.userId > 0) {
    //     //   if (this.currentUser?.role?.name === "Dealer") {
    //     //     this.dealerId = this.currentUser.dealer.dealerId;
    //     //     this.loadDeclinedConfirmations(
    //     //       this.declinedConfirmationsEndPoint + this.dealerId
    //     //     );
    //     //     this.loadDeclinedSettlements(
    //     //       this.declinedSettlementsEndPoint + this.dealerId
    //     //     );
    //     //   }
    //     // } else {
    //     //   this.currentUser = null;
    //     // }
    //   },
    //   (error) => {
    //   }
    // );
  }

  ngAfterViewInit() {
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }
  // public activateKey() {
  //   if (this.obj.key == null || this.obj.key == '') {
  //     Swal.fire("Validation Error!", "Please enter your License Key!", "error");
  //     return;
  //   }

  //   this.licensingService.post("Activate", this.obj).subscribe(
  //     args => {
  //       // console.log(args);
  //       if (args === true) {
  //         Swal.fire("Activation Succeeded!", "Please log out to effect the activation on your session!", "success");
  //         return;
  //       }
  //       else {
  //         Swal.fire("Activation Error!", args.reasonPhrase, "error");
  //         return;
  //       }
  //     },
  //     error => {
  //       // console.log(error);
  //     }
  //   );
  // }
}

// export class LicenseActivator {
//   key: string;
// }

