
<!-- <app-update-license [currentUser]="currentUser" *ngIf="!currentUser?.license?.isValid"></app-update-license>
<div class="wrapper" *ngIf="currentUser?.license?.isValid">

	<div class="content-wrapper">
		<section class="content-header" *ngIf="currentUser?.license?.daysToExpiry <= 30 && currentUser?.license?.daysToExpiry > 7"
			(click)="onNotificationClick()">
            <div class="callout callout-info">
                <h4>License Notification</h4>

                <p>Your license is about to expire in {{ currentUser?.license?.daysToExpiry }} days. Please contact Riskflow for a renewal.</p>
            </div>
        </section>
		
		<section class="content-header" *ngIf="currentUser?.license?.daysToExpiry <= 7" (click)="onNotificationClick()">
            <div class="callout callout-warning">
                <h4>License Notification</h4>

                <p>
					Your license will expire in {{ currentUser?.license?.daysToExpiry }} day(s).
					You will no longer be able to use the Treasury Platform once the license expires. 
					Click on this notification to update your product key.
				</p>
            </div>
        </section>
		
		<router-outlet></router-outlet>
		
	</div>


	<app-footer [currentUser]="currentUser"></app-footer>


	<div class="control-sidebar-bg"></div>
</div> -->
<app-vertical *ngIf="isVerticalLayoutRequested()"></app-vertical>


<app-horizontal *ngIf="isHorizontalLayoutRequested()"></app-horizontal>