import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { map } from 'rxjs/operators';
//import { UserService } from './user.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import { Router } from '@angular/router';
//import { MessageService } from './shared/message.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;
    public currentUserSubject: BehaviorSubject<User>;
    logoutObject: LogoutObject = new LogoutObject();
    public currentUser: Observable<User>;
    private subscription: Subscription;
    jwtHelper = new JwtHelperService();
    public actions: string[] = [];
    public routes: string[] = [];
  
    constructor() {
    }

    /**
     * Returns the current user
     */
    public currentUserr(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }
    public loggedIn() {
        if (localStorage.getItem('currentUser') !== 'undefined') {
          const token = localStorage.getItem('token');
    
          if (this.jwtHelper.isTokenExpired(token)) {
            // remove user from local storage to log user out
            localStorage.removeItem('token');
            localStorage.removeItem('currentUser');
    
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
      
        public get currentUserValue(): User {
        if (this.loggedIn()) {
          //return this.currentUserSubject.value;
          return null;//this.currentUserSubject.value;
        } else {
          return null;
        }
      }
    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }
    // public logout() {
    //   // console.log('Attempting a logout');
  
    //   const user = this.currentUserValue;
    //   this.logoutObject.userId = user.userId;
    //   this.logoutObject.sessionId = user.sessionId;
  
    //   this.userService.postEndpoint('Logout', this.logoutObject).subscribe(() => {
    //     // console.log('Logout successful');
    //   });
  
    //   // remove user from local storage to log user out
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('currentUser');
    //   localStorage.removeItem('idleTimeout');
      
    //   this.currentUserSubject.next(null);
    //   this.router.navigate(['/account/login']);
    // }
    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }
}

export class LogoutObject {
  userId: number;
  sessionId: string;
}

