import { Role } from './role';
import { MenuItem } from 'src/app/layouts/horizontaltopbar/menu.model';
import { Company } from './company';
import { Farmer } from './farmer';
import { License } from './license';
import { Employee } from './employee';

export class User {
    id: number;
    username: string;
    password: string;
    fullName?: string;
    token?: string;
    avatar: string;
    roleId: number;
    roleName: string;
    isActive: boolean;
    menus: MenuItem[];
    farmerId: number;
    creatorId: number;
    studentId: number;
    license: License;
    companyId: number;
    employeeId: number;
    schoolId: number;
    farmer: Farmer;
    company: Company;
    employee: Employee;
    createdAt: Date;
    lastLoginDate: Date;
    //license: License;
    sessionId: string;
    status: string;  
    routes: string[];
    role: Role;
    value:  any;
}
