<footer class="footer fixed">
    <!-- <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © QuantumSoft.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-right d-none d-sm-block">
                    Design & Development QuantumSoft
                </div>
            </div>
        </div>
    </div> -->
    <div class="pull-right hidden-xs">
        This software is licensed to <a href="#">{{currentUser?.user?.license?.licensee}}</a>.
        <b>Version</b> 2.4.0
    </div>
    <strong>Copyright &copy; {{year}} <a href="https://quantamsoft.com"> © QuantumSoft.</a>.</strong> All rights reserved.
</footer>