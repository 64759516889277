import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authFackservice: AuthfakeauthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (environment.defaultauth === 'firebase') {
            const currentUser = this.authenticationService.currentUserr();
            if (currentUser) {
                // logged in so return true
                return true;
            }
        } else {
            const isLoggedIn = this.authFackservice.isLoggedIn();
            if (isLoggedIn) {
                const link = this.getLink(route);
                console.log(link);

                if (link) {
                    const match = this.authFackservice.linkMatch(link);

                    if (match) {
                        return true;
                    } else {
                        this.router.navigate(['']);
                        return false;
                    }
                } else {
                    this.router.navigate(['']);
                    return false;
                }
            }
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    private getLink(route: ActivatedRouteSnapshot): string {
        if (route.firstChild == null) {
            console.log(route.data['link']);
            return route.data['link'] as string;
        } else {
            return this.getLink(route.firstChild);
        }
        console.log(route);
        const link = route.firstChild.data['link'] as string;
        return link;
    }
    // canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    //     const currentUser = this.auth.currentUserValue;
    
    //     if (currentUser) {
    //       this.auth.hasRoute(state.url)
    //       return true;
    //     } else {
    //       this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
    //       return false;  
    //     }
    //   }
    
}
