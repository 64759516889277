// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
 //apiPath: 'https://webservice.transfarmation.biz/',
 ////
 //apiPath: 'https://webservice.mtstmaryszw.org/',

apiPath: 'https://webservice.smartielearn.com/',

//apiPath: 'https://webservice.millennialacademy.cloud/',//
//apiPath: 'https://accademy.sakubvacc.com/webservice/',
//apiPath: 'https://108.181.171.106:443/webservice/',
//apiPath: 'https://localhost:44382/',
 // apiPath: 'https://webservice.smartielearn.com/',
  defaultauth: 'api',

  firebaseConfig: {
    apiKey: "AIzaSyDlB6Q2JJDzEbB9M7g-eYpHiivNm0dRJ4E",
    authDomain: "transfarmation-4bf54.firebaseapp.com",
    projectId: "transfarmation-4bf54",
    storageBucket: "transfarmation-4bf54.appspot.com",
    messagingSenderId: "582457286400",
    appId: "1:582457286400:web:cd868216c7b1d3116bdde5",
    
    measurementId: "G-Q9X8K2KP6X"
  }
};


// firebaseConfig:  {
//   apiKey: "AIzaSyDY3WAVC00Smfi2tlGiN07T4J2gS4XjqHg",
//   authDomain: "Transfarmation.firebaseapp.com",
//   databaseURL: "https://Transfarmation.firebaseio.com",
//   projectId: "Transfarmation",
//   storageBucket: "Transfarmation.appspot.com",
//   messagingSenderId: "1012880423249",
//   appId: "1:1012880423249:web:4b86e561d7f4a144d6bb70",
//   measurementId: "G-LHD0FQTC9Y"
// }
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
