import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/auth.models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  @Input() currentUser: any;
  // set the currenr year
  year: number = new Date().getFullYear();
  stringifyUser: any;
  constructor() { }

  ngOnInit() {
    this.stringifyUser = JSON.stringify(globalThis.currentUser);
    if(this.stringifyUser != null)
    {
    this.currentUser = JSON.parse(this.stringifyUser);
    //this.currentUser = (this.stringifyUser);
     console.log('Current User ' + this.currentUser);
    }
    console.log(this.currentUser);
  }

}
