import { Component, OnInit, AfterViewInit } from '@angular/core';

import { EventService } from '../core/services/event.service';

import {
  LAYOUT_VERTICAL, LAYOUT_HORIZONTAL
} from './layouts.model';
import { Router } from '@angular/router';

import { UserService } from '../core/services/admin/user.service';
import { AuthenticationService } from '../core/services/auth.service';
import { User } from '../core/models/auth.models';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {

  // layout related config
  layoutType: string;
  currentUser: User = null;
  year: number;
  avatarPath = '';
  daysToExpiry: any;
  constructor(
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService, private eventService: EventService) { }

  ngOnInit() {
    // default settings
    this.layoutType = LAYOUT_HORIZONTAL;

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });
    this.currentUser = this.authenticationService.currentUserValue;
    console.log(this.authenticationService.currentUserValue);
    if (this.currentUser?.id > 0) {
    } else {
      this.currentUser = null;
    }

  }
  onNotificationClick() {
    this.currentUser.license.isValid = false;
  }
  ngAfterViewInit() {
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }
}
