import { Injectable } from '@angular/core';
import { User } from '../../models/auth.models';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService<User> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'Users');
  }
}
